require("bootstrap");
import AutoNumeric from "autonumeric";
import { managePaymentOptions, validateAmountToDonate } from "../../utils";
import "../../../../stylesheets/payment_methods/recurring-selector";

$(document).ready(function () {
  if (window.currency_type == "clp") {
    $("#recurring-radio-container").show();
  }
  $(".currency-selection label").click(({ target }) => {
    var currency = target.getAttribute("data-currency");
    if (currency == "clp") {
      $("#recurring-radio-container").show();
    } else {
      $("#recurring-radio-container").hide();
    }
  });
  $('[name="recurring_radio_with_modal"]').change(function () {
    if (this.value == "subscription") {
      $("#delayed-subscription-form").modal("show");
    } else {
      $(
        "#donator_donation_transactions_attributes_0_delayed_subscription_amount"
      )[0].value = "";
      managePaymentOptions(window.currency_type, "once");
    }
  });

  new AutoNumeric.multiple(
    "#donator_donation_transactions_attributes_0_delayed_subscription_amount",
    {
      digitGroupSeparator: ".",
      decimalCharacter: ",",
      decimalPlaces: 0,
    }
  );

  $("#delayed-subscription-form .close").on("click", function () {
    $('[name="recurring_radio_with_modal"]#single-donation')[0].checked = true;
  });

  $("#confirm-delayed-subscription-button").click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    const validation = validateAmountToDonate(
      "subscription",
      "clp",
      "#donator_donation_transactions_attributes_0_delayed_subscription_amount"
    );
    if (validation.valid) {
      const selector = ".payment-selection .custom-control";
      $(`${selector}`).addClass("hidden-input");
      $(`${selector}.payment-clp.payment-delayed-subscription`).removeClass(
        "hidden-input"
      );
      $("#delayed-subscription-form").modal("hide");
      $(
        "#donator_donation_transactions_attributes_0_delayed_subscription_amount"
      )[0].value = validation.amount;
    }
  });
});
